import React, { useEffect, useState, useContext } from 'react';
import ImagesContext from "../contexts/ImagesContext";
import GridGallery from 'react-grid-gallery';

export default function Gallery() {
  const [images, setImages] = useState([]);
  const imagesContext = useContext(ImagesContext);
  const { imageURLs } = imagesContext;

  useEffect(() => {
    const imagesFromStorage = []
    imageURLs.forEach((downloadUrl) => {
      imagesFromStorage.push({
        src: downloadUrl,
        thumbnail: downloadUrl
      })
    });
    setImages(imagesFromStorage);
  }, [imageURLs]);

  return (
    <GridGallery
      images={images} />
  )
}