import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Grid, Box, List, ListItem,
  ListItemIcon, ListItemText, Paper,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { green } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import { useSpring, animated } from 'react-spring'
import RequestQuoteForm from '../components/RequestQuoteForm';
import BackgroundVideo from '../components/BackgroundVideo';
import ReactCompareImage from 'react-compare-image';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Gallery from '../components/Gallery';
import ImagesProvider from '../contexts/ImagesProvider';
import FeedbacksList from '../components/FeedbacksList';
import FeedbackForm from '../components/FeedbackForm';
import FeedbacksProvider from '../contexts/FeedbacksProvider';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import ScrollAnimation from 'react-animate-on-scroll';


const storage = getStorage();
const beforeImageRef = ref(storage, 'before-after-images/before.jpg');
const afterImageRef = ref(storage, 'before-after-images/after.jpg');

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    spacing: 0,
    justify: 'space-around',
  },
  introContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '150px',
    },
    marginTop: '100px',
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5%',
      marginTop: '150px',
      paddingBottom: '0px',
    },
    paddingLeft: '0%',
    marginTop: '50px',
    paddingBottom: '50px',
  },
  beforeAfterContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '50px',
    },
    marginTop: '0px',
    paddingBottom: '50px',
  },
  feedbacksContainer: {
    marginTop: '50px',
  },
  introTitle: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    paddingRight: 20,
    paddingLeft: 20,
    textAlign: 'center',
    marginTop: '2%',
  },
  introDescription: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '17px',
    paddingRight: 20,
    paddingLeft: 20,
  },
  listItem: {
    height: '30px',
  },
  compareImageBox: {
    marginTop: '20px',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      padding: '50px'
    },
    padding: '20px'
  }
}))

export default function HomePage() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 500 } })
  const classes = useStyles()
  const services = [
    "Framing", "Painting", "Flooring", "Cabinet/Countertop", "Deck", "Roof",
    "Fence", "Windows", "Bathroom",
  ]
  const [beforeImage, setBeforeImage] = useState("");
  const [afterImage, setAfterImage] = useState("");
  const [showDownIcon, setShowDownIcon] = useState(true);

  useEffect(() => {
    getDownloadURL(beforeImageRef)
      .then((url) => {
        setBeforeImage(url);
      })
      .catch((error) => {
        console.error(error);
      });

    getDownloadURL(afterImageRef)
      .then((url) => {
        setAfterImage(url);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -250) {
      setShowDownIcon(false)
    } else if (currPos.y === 0) {
      setShowDownIcon(true)
    }
  });

  const handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight - 200,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <animated.div
      style={props}>
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        direction="row">

        <Grid
          xs={12}
          md={6}
          item
          className={classes.introContainer}>
          <Paper style={{ padding: 16 }}>

            <h3 className={classes.introTitle}>About Us</h3>

            <br />
            <p className={classes.introDescription} style={{ marginTop: '20px' }}>3R Construction LLC is a general contractor
              that specializes in whole home remodeling. Based in Seattle area, we have the resources to provide everything you're looking for in a home.</p>
            <br />
            <p className={classes.introDescription}>These are some of the services we offer:</p>
            <List className={classes.servicesList}>
              {
                services.map((value) => {
                  return (
                    <ListItem className={classes.listItem} key={value}>
                      <ListItemIcon style={{ color: green[500] }}>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={value} />
                    </ListItem>
                  )
                })
              }
            </List>
          </Paper>
        </Grid>

        <Grid
          xs={12}
          md={6}
          item
          className={classes.formContainer}>
          <RequestQuoteForm services={services} />
        </Grid>

        <Grid
          item
          className={classes.beforeAfterContainer}
          xs={12}>
          <Paper
            className={classes.paper}
          >

            <h1
              style={{ margin: 'auto', textAlign: 'center', marginBottom: '20px' }}>
              Before & After
            </h1>

            <Box
              className={classes.compareImageBox}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ReactCompareImage
                leftImage={beforeImage}
                rightImage={afterImage}
                leftImageLabel="Before"
                rightImageLabel="After"
                aspectRatio="wider"
              />
            </Box>

          </Paper>
        </Grid>

        <Grid
          style={{ paddingBottom: '50px' }}
          item
          xs={12}>
          <Paper
            className={classes.paper}
          >
            <h1
              style={{ margin: 'auto', textAlign: 'center', marginBottom: '50px' }}>
              Gallery
            </h1>
            <ImagesProvider>
              <Grid
                container
                justifyContent="center"
                direction="row">
                <Grid
                  xs={12}
                  item>
                  <Gallery />
                </Grid>
              </Grid>
            </ImagesProvider>
          </Paper>
        </Grid>

        <Grid
          style={{ paddingBottom: '50px' }}
          item
          xs={12}>
          <Paper
            className={classes.paper}
          >
            <FeedbacksProvider>
              <Grid
                container
                direction="row">
                <Grid
                  className={classes.titleContainer}
                  item
                  xs={12}>
                  <h1 style={{ marginBottom: '50px' }} className={classes.introTitle}>
                    What our customers are saying
                  </h1>
                </Grid>
                <Grid
                  xs={12}
                  className={classes.feedbackSliderContainer}
                  item>
                  <FeedbacksList />
                </Grid>
                <Grid
                  xs={12}
                  className={classes.feedbackSliderContainer}
                  style={{ marginTop: '30px' }}
                  item>
                  <FeedbackForm />
                </Grid>
              </Grid>
            </FeedbacksProvider>
          </Paper>
        </Grid>

        {
          showDownIcon &&
          <IconButton
            onClick={handleScroll}
            color="primary"
            style={{ position: 'fixed', bottom: '25px' }}>
            <KeyboardArrowDownIcon style={{ fontSize: 60 }} />
          </IconButton>
        }

        <BackgroundVideo />
      </Grid>
    </animated.div>
  );
}
