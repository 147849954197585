import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backgroundVideo: {
        zIndex: -1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        objectFit: "cover",
    }
}))

export default function BackgroundVideo() {
    const classes = useStyles();
    const videoUrl = "https://d2hmgpwhxrgxc0.cloudfront.net/background.mp4"

    return (
        <video
            className={classes.backgroundVideo}
            autoPlay
            loop
            muted>
            <source src={videoUrl} type="video/mp4" />
        </video>
    );
}