import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    FormControlLabel,
    Switch,
    Paper,
    Box,
} from '@material-ui/core';
import FeedbacksContext from '../contexts/FeedbacksContext';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}))

export default function AdminFeedbacks() {
    const classes = useStyles();
    const feedbacksContext = useContext(FeedbacksContext);
    const { feedbacks, updateFeedback } = feedbacksContext;
    const [localFeedbacks, setLocalFeedbacks] = useState([]);

    useEffect(() => {
        setLocalFeedbacks(feedbacks);
    }, [feedbacks]);

    const handleChange = (event, id) => {
        const copiedFeedbacks = [...localFeedbacks];
        const feedBackToUpdateIndex = copiedFeedbacks.findIndex(item => item.id === id);
        copiedFeedbacks[feedBackToUpdateIndex].show = event.target.checked;
        updateFeedback(copiedFeedbacks[feedBackToUpdateIndex]);
        setLocalFeedbacks(copiedFeedbacks);
    };

    return (
        <Grid
            container>
            {
                localFeedbacks.map((value, index) => {
                    return (
                        <Grid
                            key={index}
                            xs={12}
                            item>
                            <Paper style={{ padding: '15px', marginTop: '15px' }}>
                                {value.feedbackName} --- "{value.feedback}"
                                <Box style={{ marginTop: '10px' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value.show}
                                                onChange={(event) => handleChange(event, value.id)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Show this feedback"
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}
