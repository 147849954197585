import React from 'react';
import {
  ThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from './components/NavBar';
import { Container } from '@material-ui/core';
import AdminPage from './pages/AdminPage';


const theme = createTheme({

})

function App() {
  let location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Router>
          {location.pathname === '/admin' ? null : <NavBar />}
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
          </Switch>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
