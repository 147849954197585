import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Box,
    Divider,
} from '@material-ui/core';
import Gallery from 'react-grid-gallery';
import ImagesContext from "../contexts/ImagesContext";
import ImageUploader from "react-images-upload";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    itemBeforeImage: {
        [theme.breakpoints.up('md')]: {
            paddingRight: '10px',
        },
        paddingRight: '0px',
    },
    itemAfterImage: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '10px',
        },
        paddingLeft: '0px',
    },
}))

export default function AdminBeforeAfter() {
    const classes = useStyles();
    const imagesContext = useContext(ImagesContext);
    const { uploadBeforeImage, uploadAfterImage } = imagesContext;
    const [beforeImageToUpload, setBeforeImageToUpload] = useState([]);
    const [afterImageToUpload, setAfterImageToUpload] = useState([]);

    const onBeforeImageDrop = picture => {
        setBeforeImageToUpload([...beforeImageToUpload, picture]);
    };

    const onAfterImageDrop = picture => {
        setAfterImageToUpload([...afterImageToUpload, picture]);
    };

    const onUploadBeforeImageClicked = () => {
        if (beforeImageToUpload.length === 0) return;
        uploadBeforeImage(beforeImageToUpload[beforeImageToUpload.length - 1][0])
            .then(() => {
                console.log("Change before image success");
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const onUploadAfterImageClicked = () => {
        if (afterImageToUpload.length === 0) return;
        uploadAfterImage(afterImageToUpload[afterImageToUpload.length - 1][0])
            .then(() => {
                console.log("Change after image success");
            })
            .catch((error) => {
                console.error(error);
            })
    };

    return (
        <Grid
            container>
            <Grid
                className={classes.itemBeforeImage}
                xs={12}
                md={6}
                item>
                <ImageUploader
                    withPreview={true}
                    withIcon={true}
                    singleImage={true}
                    buttonText="Choose before image"
                    onChange={onBeforeImageDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                />
                <Box
                    style={{ marginTop: '20px', marginBottom: '50px' }}
                    display="flex"
                    justifyContent="end">
                    <Button
                        onClick={onUploadBeforeImageClicked}
                        variant="outlined"
                        color="primary">Change Before Image</Button>
                </Box>
            </Grid>

            <Grid
                className={classes.itemAfterImage}
                xs={12}
                md={6}
                item>
                <ImageUploader
                    withPreview={true}
                    withIcon={true}
                    singleImage={true}
                    buttonText="Choose after image"
                    onChange={onAfterImageDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                />
                <Box
                    style={{ marginTop: '20px', marginBottom: '50px' }}
                    display="flex"
                    justifyContent="end">
                    <Button
                        onClick={onUploadAfterImageClicked}
                        variant="outlined"
                        color="primary">Change After Image</Button>
                </Box>
            </Grid>
        </Grid>
    );
}
