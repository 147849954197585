import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Box,
    Divider,
} from '@material-ui/core';
import Gallery from 'react-grid-gallery';
import ImagesContext from "../contexts/ImagesContext";
import ImageUploader from "react-images-upload";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}))

export default function AdminImages() {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const imagesContext = useContext(ImagesContext);
    const { imageURLs, fetchAllImages, uploadImages, deleteImages } = imagesContext;
    const [imagesToUpload, setImagesToUpload] = useState([]);

    useEffect(() => {
        const imagesFromStorage = []
        imageURLs.forEach((downloadUrl) => {
            imagesFromStorage.push(
                {
                    src: downloadUrl,
                    thumbnail: downloadUrl,
                    isSelected: false
                }
            )
        });
        setImages(imagesFromStorage);
    }, [imageURLs]);

    const onSelectImage = (index) => {
        const copiedImages = [...images];
        for (let i = 0; i < copiedImages.length; i++) {
            if (i === index) {
                copiedImages[i].isSelected = !copiedImages[i].isSelected
            }
        }
        setImages(copiedImages);
    };

    const onDrop = picture => {
        setImagesToUpload([...imagesToUpload, picture]);
    };

    const onUploadImagesClicked = () => {
        if (imagesToUpload.length === 0) return;
        uploadImages(imagesToUpload[imagesToUpload.length - 1])
            .then(() => {
                fetchAllImages();
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const onDeleteImagesClicked = () => {
        const selectedImages = []
        images.forEach((image) => {
            if (image.isSelected) {
                selectedImages.push(image);
            }
        });
        deleteImages(selectedImages)
            .then(() => {
                fetchAllImages();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Grid
            container>
            <Grid
                xs={12}
                item>
                <ImageUploader
                    withPreview={true}
                    withIcon={true}
                    onChange={onDrop}
                    imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                />
                <Box
                    style={{ marginTop: '20px', marginBottom: '50px' }}
                    display="flex"
                    justifyContent="end">
                    <Button
                        onClick={onUploadImagesClicked}
                        variant="outlined"
                        color="primary">Upload Images</Button>
                </Box>
            </Grid>

            {
                images.length > 0 && (
                    <>
                        <Grid
                            style={{ marginTop: '0px', marginBottom: '50px' }}
                            xs={12}
                            item>
                            <Divider variant="fullWidth" />
                        </Grid>

                        <Grid
                            xs={12}
                            item>
                            <Gallery
                                enableLightbox={false}
                                onClickThumbnail={onSelectImage}
                                onSelectImage={onSelectImage}
                                images={images} />
                        </Grid>

                        <Grid
                            style={{ marginTop: '20px', marginBottom: '100px' }}
                            xs={12}
                            item>
                            <Box
                                display="flex"
                                justifyContent="end">
                                <Button
                                    onClick={onDeleteImagesClicked}
                                    variant="outlined"
                                    color="secondary">
                                    Delete Selected Images
                                </Button>
                            </Box>
                        </Grid></>
                )
            }
        </Grid>
    );
}
