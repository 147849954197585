import React, { useState, useEffect } from "react";
import FeedbacksContext from "./FeedbacksContext";
import fireBaseApp from '../Firebase';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";

const db = getFirestore(fireBaseApp);

export default function FeedbacksProvider(props) {
    const [feedbacks, setFeedbacks] = useState([]);

    const getFeedbacks = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "feedbacks"));
            const feedbacksList = [];
            querySnapshot.forEach((doc) => {
                feedbacksList.push({
                    id: doc.id,
                    feedback: doc.data().feedback,
                    feedbackName: doc.data().feedbackName,
                    show: doc.data().show,
                })
            });
            setFeedbacks(feedbacksList)
        } catch (e) {
            console.error(e);
        }
    }

    const addFeedback = async (values) => {
        try {
            await addDoc(collection(db, "feedbacks"), values);
            setFeedbacks([
                ...feedbacks,
                values
            ]);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const updateFeedback = (newFeedback) => {
        const ref = doc(db, "feedbacks", newFeedback.id);
        updateDoc(ref, {
            show: newFeedback.show
        });
    }

    useEffect(() => {
        getFeedbacks();
    }, [])

    return (
        <FeedbacksContext.Provider
            value={{ feedbacks, addFeedback, updateFeedback }}
        >
            {props.children}
        </FeedbacksContext.Provider>
    );
}