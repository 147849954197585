import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tabs,
    Tab,
    AppBar,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    Button,
    Box
} from '@material-ui/core';
import { useSpring, animated } from 'react-spring'
import AdminImages from '../components/AdminImages';
import ImagesProvider from '../contexts/ImagesProvider';
import AdminBeforeAfter from '../components/AdminBeforeAfter';
import FeedbacksProvider from '../contexts/FeedbacksProvider';
import AdminFeedbacks from '../components/AdminFeedbacks';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function AdminPage() {
    const classes = useStyles()
    const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 500 } })
    const [value, setValue] = useState(0);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePassChange = (event) => {
        setPass(event.target.value)
    };

    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, pass)
            .then((userCredential) => {
                console.log("Signed in successful");
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <animated.div style={props}>
            {
                user == null ?
                    <>
                        <Grid
                            container
                            style={{ marginTop: '100px' }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item>
                                <form noValidate autoComplete="off">
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="component-outlined">Email</InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            onChange={handleEmailChange}
                                            label="Email" />
                                    </FormControl>
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="component-outlined">Password</InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            type="password"
                                            onChange={handlePassChange}
                                            label="Password" />
                                    </FormControl>
                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                        style={{ marginTop: '25px' }}>
                                        <Button variant="outlined" onClick={handleLogin}>Login</Button>
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label="Gallery" />
                                {/* <Tab label="Before/After Images" /> */}
                                <Tab label="Feedbacks" />
                            </Tabs>
                        </AppBar>
                        <div
                            style={{ marginTop: '20px' }}
                            role="tabpanel"
                            hidden={value !== 0}
                            id={`simple-tabpanel-${0}`}
                            aria-labelledby={`simple-tab-${0}`}
                        >
                            {value === 0 && (
                                <ImagesProvider>
                                    <AdminImages />
                                </ImagesProvider>
                            )}
                        </div>
                        <div
                            style={{ marginTop: '20px' }}
                            role="tabpanel"
                            hidden={value !== 1}
                            id={`simple-tabpanel-${1}`}
                            aria-labelledby={`simple-tab-${1}`}
                        >
                            {value === 1 && (
                                // <ImagesProvider>
                                //     <AdminBeforeAfter />
                                // </ImagesProvider>
                                <FeedbacksProvider>
                                    <AdminFeedbacks />
                                </FeedbacksProvider>
                            )}
                        </div>
                        {/* <div
                            style={{ marginTop: '20px' }}
                            role="tabpanel"
                            hidden={value !== 2}
                            id={`simple-tabpanel-${2}`}
                            aria-labelledby={`simple-tab-${2}`}
                        >
                            {value === 2 && (
                                <FeedbacksProvider>
                                    <AdminFeedbacks />
                                </FeedbacksProvider>
                            )}
                        </div> */}
                    </>
            }
        </animated.div>
    );
}
