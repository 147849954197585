import React, { useState, useEffect } from "react";
import ImagesContext from "./ImagesContext";
import {
    getStorage,
    ref,
    getDownloadURL,
    listAll,
    uploadBytes,
    deleteObject,
} from "firebase/storage";

const storage = getStorage();
const listRef = ref(storage, 'images');


export default function FeedbacksProvider(props) {
    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        fetchAllImages();
    }, []);

    const fetchAllImages = () => {
        listAll(listRef)
            .then((res) => {
                const imagesFromStorage = []
                const downloadURLPromises = [];

                res.items.forEach((item) => {
                    downloadURLPromises.push(getDownloadURL(ref(storage, item.fullPath)));
                });

                Promise.all(downloadURLPromises)
                    .then((downloadURLs) => {
                        downloadURLs.forEach((downloadUrl) => {
                            imagesFromStorage.push(downloadUrl)
                        });
                        setImageURLs(imagesFromStorage);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            }).catch((error) => {
                console.error(error);
            });
    }

    const uploadImages = async (images) => {
        const uploadBytesList = []

        images.forEach((file) => {
            const path = 'images/' + file.name;
            const storageRef = ref(storage, path);
            const metadata = {
                contentType: file.type,
            };
            uploadBytesList.push(uploadBytes(storageRef, file, metadata));
        });

        return Promise.all(uploadBytesList);
    }

    const uploadBeforeImage = async (image) => {
        const uploadBytesList = []
        const fileExtention = image.name.split(".").pop();
        const path = 'before-after-images/' + "before." + fileExtention;
        console.log(path);
        const storageRef = ref(storage, path);
        const metadata = {
            contentType: image.type,
        };
        uploadBytesList.push(uploadBytes(storageRef, image, metadata));

        return Promise.all(uploadBytesList);
    }

    const uploadAfterImage = async (image) => {
        const uploadBytesList = []
        const fileExtention = image.name.split(".").pop();
        const path = 'before-after-images/' + "after." + fileExtention;
        console.log(path);
        const storageRef = ref(storage, path);
        const metadata = {
            contentType: image.type,
        };
        uploadBytesList.push(uploadBytes(storageRef, image, metadata));

        return Promise.all(uploadBytesList);
    }

    const deleteImages = async (images) => {
        const deleteObjectList = []

        images.forEach((file) => {
            const storageRef = ref(storage, file.src);
            deleteObjectList.push(deleteObject(storageRef));
        });

        return Promise.all(deleteObjectList);
    }

    return (
        <ImagesContext.Provider
            value={{ imageURLs, fetchAllImages, uploadImages, uploadBeforeImage, uploadAfterImage, deleteImages }}
        >
            {props.children}
        </ImagesContext.Provider>
    );
}