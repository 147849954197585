import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import {
  Grid, Paper, Button, MenuItem
} from '@material-ui/core';
import fireBaseApp from '../Firebase';
import { getFirestore, collection, addDoc, } from "firebase/firestore"
import validator from 'validator';
import toast, { Toaster } from 'react-hot-toast';
import emailjs from 'emailjs-com';



const db = getFirestore(fireBaseApp);

const useStyles = makeStyles((theme) => ({
  introTitle: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontSize: '16px',
    textAlign: "center",
  },
}))

const onSubmit = async values => {

  try {
    var selectedService = "";
    if (values.service !== undefined) {
      selectedService = values.service;
    }

    var selectedNotes = "";
    if (values.notes !== undefined) {
      selectedNotes = values.notes;
    }

    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      service: selectedService,
      notes: selectedNotes,
    };

    await addDoc(collection(db, "quote_requests"), data);

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      data, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    toast.success('Thank you! We\'ll talk soon.', {
      duration: 4000,
      style: {
        backgroundColor: 'green',
        color: 'white',
        fontWeight: 'bold'
      }
    })
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!validator.isEmail(values.email)) {
    errors.email = 'Invalid email';
  }
  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!validator.isMobilePhone(values.phone)) {
    errors.phone = 'Invalid phone number';
  }
  return errors;
};

export default function RequestQuoteForm({ services }) {
  const classes = useStyles()


  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, reset, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Paper style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '25px', paddingBottom: '25px', }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} justifyContent="center" className={classes.introTitle}><h3>We are looking forward to hearing from you!</h3></Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  required
                  name="firstName"
                  component={TextField}
                  type="text"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  required
                  name="lastName"
                  component={TextField}
                  type="text"
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="email"
                  fullWidth
                  required
                  component={TextField}
                  type="email"
                  label="Email"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="phone"
                  fullWidth
                  required
                  component={TextField}
                  type="text"
                  label="Phone"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="service"
                  component={Select}
                  label="Select a service"
                  formControlProps={{ fullWidth: true }}
                >
                  {
                    services.map((value, key) => {
                      return (
                        <MenuItem
                          value={value}
                          key={key}>
                          {value}
                        </MenuItem>
                      )
                    })
                  }
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="notes"
                  component={TextField}
                  multiline
                  label="Notes"
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  Request a free quote
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.introTitle}><h3>Or call us at <a href="tel:+1-206-335-5488" style={{ color: 'black' }}>(206) 335-5488</a> for a free estimate.</h3></Grid>
            </Grid>
          </Paper>
          <Toaster />
        </ form>
      )}
    />
  )
}