import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from "@material-ui/core";
import FeedbacksContext from "../contexts/FeedbacksContext";

const useStyles = makeStyles((theme) => ({
  feedbackItemContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  feedback: {
    fontSize: '15px',
    paddingBottom: '10px',
  },
  feedbackName: {
    fontSize: '12px',
    fontStyle: 'italic',
  }
}))

export default function FeedbacksList() {
  const classes = useStyles();
  const feedbacksContext = useContext(FeedbacksContext);
  const { feedbacks } = feedbacksContext;

  return (
    <Grid
      container>
      {
        feedbacks.map((value, index) => {
          return (
            value.show && <Grid
              key={index}
              className={classes.feedbackItemContainer}
              item
              xs={12}>
              <Paper style={{ padding: '20px' }}>
                <Typography
                  className={classes.feedback}
                  display="block"
                  variant="caption">
                  {value.feedback}
                </Typography>
                <Typography
                  className={classes.feedbackName}
                  variant="caption">
                  - {value.feedbackName}
                </Typography>
              </Paper>

            </Grid>
          )
        })
      }
    </Grid>
  )
}